import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@ocr-gateway/ui';
import { RouteType, actResetUsageDateFilter } from '@ocr-gateway/shared';
import _ from 'lodash';

interface RoutesProps {
  routes: Array<RouteType>;
}
export const Routes: React.FC<RoutesProps> = ({ routes }) => {
  const currentOrganization = useSelector((state: any) => state.auth.user.organization);
  const hasRoles = (arr: string[]) => {
    const found = arr.some(item => user.roles.includes(item));
    return found;
  };
  const user = useSelector((state: any) => state.auth.user);
  return (
    <Switch>
      {routes.map(({ path, component: Component, isPrivate, allowedRoles }, idx) => (
        <PrivateRoute
          exact
          path={path}
          key={path + currentOrganization.id}
          isAuthenticated={!isPrivate || (isPrivate && hasRoles(allowedRoles))}
          component={Component}
        />
      ))}
    </Switch>
  );
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  currentOrganization,
  path,
  ...rest
}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!_.isEqual(path, '/')) {
      dispatch(actResetUsageDateFilter());
    }
  }, [path]);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Component organization={currentOrganization} />
          </ErrorBoundary>
        ) : (
          <Redirect to="/account-settings" />
        )
      }
    />
  );
};
