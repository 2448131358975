import React, { useState, useEffect } from 'react'
import { Icon, SmartHubOption } from '@ocr-gateway/ui';
import { Api } from "@ocr-gateway/shared";
import { batch, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSimpleExtraction, setUploadPopupWithSmarCapture } from '@ocr-gateway/shared';
import { linkToHubEditPostfix, linkToHubs } from "../../../../constants";
import styled from "@emotion/styled";
import { StyledLoaderUpload } from '../../styles';

const SmartHubListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    height: 300px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 10px;
`


export const SmartHubList = ({ onCancel }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [smartHubs, setSmartHubs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const onOptionClick = async (id) => {
        setIsLoading(true)
        dispatch(setUploadPopupWithSmarCapture({ showLoader: true }));
        const data = await Api.createSmartHub({ dispatch, smartHubId: id })
        dispatch(setSimpleExtraction(false));
        history.push(linkToHubs + '/' + data.id + linkToHubEditPostfix)
        onCancel()
        dispatch(setUploadPopupWithSmarCapture({ showLoader: false }))
        setIsLoading(false)
    }

    useEffect(() => {
        (async () => {
            const data = await Api.getSmartHubs();
            setSmartHubs(data);
        })();
    }, [])

    return (

        isLoading ? (
            <StyledLoaderUpload>
                <div className="loader">
                    <Icon name="bubble-spinner" />
                </div>
                <span className="loading">Loading</span>
                <span className="text">Please wait a moment...</span>
            </StyledLoaderUpload >
        ) : (
            <SmartHubListContainer>
                {smartHubs.map(hub => (
                    <SmartHubOption onClick={() => onOptionClick(hub.id)}
                        key={hub.id}
                        iconName={'smart-hub-doc'}
                        name={hub.name}
                        description={hub.description}
                    />
                ))}
            </SmartHubListContainer>
        )


    )
};