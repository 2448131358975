import styled from '@emotion/styled';

export const ButtonSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 60px;
  margin-right: 60px;
`;
export const GreyBackground = styled.div`
  position: absolute;
  background-color: gray;
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;

export const TranslateContainer = styled.div`
  display: flex;
  height: 90vh;
  border: 2px solid #0251f7;
  position: relative;
  margin-left: 10px;
`;

export const TranslateModeTag = styled.div`
  position: absolute;
  text-transform: uppercase;
  background-color: #0251f7;
  color: white;
  padding: 4px;
  z-index: 10;
`;
export const TranslateInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 53px;
`;
export const LanguageSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
`;
export const IconSection = styled.section<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  padding: 10px;
  background-color: ${({ isSelected }) => (isSelected ? '#C0D3FD' : 'none')};
  &:hover {
    background-color: #d9e5fe;
    cursor: pointer;
  }
`;

export const PdfViewerHeader = styled.div`
  display: flex;
  background-color: #020935;
  height: 52px;
  justify-content: flex-end;
`;
export const CustomHeaderButton = styled.button`
  position: relative;
  outline: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 8px 12px;
  transition: 100ms linear;
  font-size: 15px;
  font-weight: 500;
  &.toolbar-pdf-fixed {
    padding: 8px 12px;
    font-size: 15px;
    @media only screen and (max-width: 1150px) {
      padding: 8px 4px;
    }
  }

  &,
  svg {
    path,
    rect,
    circle {
      transition: 100ms linear;
    }
  }

  svg:not(.no-m) {
    margin-right: 12px;
    // transform: translateY(3px);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &-selected {
    background: rgba(224, 189, 189, 0.3);
    color: #ff715b;
    // color: #558AFB;
    svg path {
      fill: #ff715b;
      // fill: #558AFB;
    }

    .save-icon {
      path,
      rect,
      circle {
        fill: none;
        stroke: #ff715b;
      }
    }
    .extract-table {
      rect {
        stroke: #ff715b;
      }
    }

    .extract-pattern,
    .extract-form,
    .extract-area {
      rect {
        fill: #ff715b;
      }
    }
  }
  .m-auto {
    margin: 0 auto;
  }

  &.refresh {
    border: 1px solid #0251f7;
    min-width: 107px;
    path {
      fill: white;
    }
    &:not(:disabled):hover {
      background: initial;
      color: #0251f7;
      path {
        fill: #0251f7;
      }
    }
  }

  &.pulsing {
    box-shadow: 0 0 0 0 rgba(2, 81, 247, 0.5);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:disabled {
    color: #bdbdbd;
    path,
    rect,
    circle {
      fill: #bdbdbd;
      stroke: #bdbdbd;
    }
  }

  &.prev {
    margin: 5px;
    @media only screen and (max-width: 1150px) {
      margin: 0;
    }
  }

  &.download {
    cursor: default;
    z-index: 10;
    .dropdown {
      position: absolute;
      cursor: pointer;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #020935;
      display: flex;
      align-items: flex-start;
      padding-left: 12px;
      flex-direction: column;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
      transition: 100ms linear;
      z-index: 0;
      svg path {
        fill: #fff;
      }

      .option {
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 12px;
        color: #fff;
        path,
        rect,
        circle {
          fill: #fff;
        }

        &:hover {
          color: #ff715b;
          svg path {
            fill: #ff715b;
          }
        }
      }

      .disabled {
        color: #bdbdbd;
        cursor: not-allowed;
        &,
        &:hover {
          color: #bdbdbd;
          svg {
            path,
            rect,
            circle {
              fill: #bdbdbd;
            }
          }
        }
      }
    }
    //
    .dropup {
      position: absolute;
      cursor: pointer;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #020935;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      transform: translateY(-29%);
      transition: 100ms linear;
      z-index: 0;
      svg path {
        fill: #fff;
      }

      .option {
        display: flex;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        width: 100%;
        color: #fff;
        path,
        rect,
        circle {
          fill: #fff;
        }

        &:hover {
          color: #ff715b;
          background: #4e5372;
          svg path {
            fill: #ff715b;
          }
        }
      }

      .disabled {
        color: #bdbdbd;
        cursor: not-allowed;
        &,
        &:hover {
          color: #bdbdbd;
          svg {
            path,
            rect,
            circle {
              fill: #bdbdbd;
            }
          }
        }
      }
    }

    &:not(:disabled):hover {
      .chevron {
        transform: rotate(180deg);
      }
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
      .dropup {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
export const Line = styled.div`
  padding: 0px;
  content: '';
  top: -6px;
  right: 0;
  height: 52px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
`;
