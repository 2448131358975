import React from 'react'
import { connect } from 'react-redux'
import { InviteUserPopup as InviteUserPopupUi } from '@ocr-gateway/ui'

import { setInviteOwnerPopup } from '@ocr-gateway/shared';
import { Api } from "@ocr-gateway/shared";

export const InviteOwnerPopupComponent = ({
  dispatch,
  inviteOwner,
  header = 'Invite New User'
}: any) => {
  const handleInviteUserClose = (e:React.MouseEvent) => {
    dispatch(setInviteOwnerPopup({
      ...inviteOwner,
      show: false
    }))
  }

  const handleInviteUserByEmail = async (e: React.MouseEvent, email: string, overrideLastInvite?: boolean) => {
    try {
      await Api.createRegisterInvite({
        email,
        overrideLastInvite
      })
      dispatch(setInviteOwnerPopup({
        ...inviteOwner,
        showWarning: false,
        showSuccess: true
      }))
    } catch (e) {
      const { errorMessage, canInviteAgain } = e
      const warningText = errorMessage
      const warningQuestion = canInviteAgain ? '\n Would you like to send an invite once again?' : ''
      dispatch(setInviteOwnerPopup({
        ...inviteOwner,
        showSuccess: false,
        showWarning: true,
        showWarningOk: canInviteAgain,
        warningText: warningText + warningQuestion
      }))
    }
  }

  const handleInviteUserWarningClose = (e: React.MouseEvent) => {
    dispatch(setInviteOwnerPopup({
      ...inviteOwner,
      showWarning: false
    }))
  }
  return (
    <InviteUserPopupUi 
      show={inviteOwner.show}
      showSuccess={inviteOwner.showSuccess}
      showWarning={inviteOwner.showWarning}
      showWarningOk={inviteOwner.showWarningOk}
      warningText={inviteOwner.warningText}
      header={header}
      warningHeader={inviteOwner.showWarningOk ? 'Need Approval' : 'Can Not Invite'}
      onClose={handleInviteUserClose}
      onInviteUser={handleInviteUserByEmail}
      onWarningClose={handleInviteUserWarningClose}
      buttonText={'invite owner'}
    />
  )
}


function mapStateToProps(state: any) {
  return {
    inviteOwner: state.popups.inviteOwner,
  };
}

export const InviteOwnerPopup = connect(
  mapStateToProps
)(InviteOwnerPopupComponent)