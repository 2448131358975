import React, { Suspense, useMemo } from 'react';
import {
  predefinedHostname,
  userInCustomHostnameUrlList,
  COOKIE_PREFIX
} from '@ocr-gateway/shared';
import { RootStateOrAny, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import { predefinedHostnameRoutes } from '../constants';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { DefaultSpinner } from '@ocr-gateway/ui';

export const HostnameWrapper = ({ children }: { children: React.ReactNode }) => {
  const { userId: loggedUserId } = useSelector((state: RootStateOrAny) => ({
    userId: state.auth.user.id
  }));
  const accessToken = useMemo(() => Cookies.get(`access-token${COOKIE_PREFIX}`), [loggedUserId]);
  const refreshToken = useMemo(() => Cookies.get(`refresh-token${COOKIE_PREFIX}`), [loggedUserId]);
  return (
    <>
      {predefinedHostname &&
      (_.isEmpty(accessToken) || _.isEmpty(refreshToken) || userInCustomHostnameUrlList) ? (
        <Router>
          <Suspense fallback={<DefaultSpinner />}>
            <Routes routes={predefinedHostnameRoutes} />
          </Suspense>
        </Router>
      ) : (
        children
      )}
    </>
  );
};
