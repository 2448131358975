import React, { useState, useMemo, useEffect, FC } from 'react'
import useLogout from '../hooks/useLogout';
import { Api } from '@ocr-gateway/shared';
import styled from '@emotion/styled';
import { Button, SimplePopup } from '@ocr-gateway/ui';

interface SessionTimeoutProps {
    minutes: number;
}

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SessionTimeout: FC<SessionTimeoutProps> = ({ minutes }) => {
    const [showPopup, setShowPopup] = useState(false)
    const seconds = minutes * 60

    const [timer, resetTimer] = useLogout(seconds, showPopup, false)
    let timerInMinutes = useMemo(() => Math.ceil(timer / 60), [timer]);

    useEffect(() => {
        if (timerInMinutes == 0) {
            Api.logOut();
        }
        if (timerInMinutes <= 5) {
            setShowPopup(true)
        } else {
            setShowPopup(false)
        }
    }, [showPopup, timerInMinutes])

    return <SimplePopup show={showPopup} header="Warning">
        <strong>Warning!</strong>
        <p>You will be logged out after 5 minutes due to inactivity.</p>
        <ButtonSection>
            <Button onClick={resetTimer}>OK</Button>
        </ButtonSection>
    </SimplePopup>

}

export default SessionTimeout;
