import React, { useEffect, FC } from 'react'
import useLogout from '../hooks/useLogout';
import { Api } from '@ocr-gateway/shared';
import styled from '@emotion/styled';
import { Button, SimplePopup } from '@ocr-gateway/ui';

interface ChangeRolePopupProps {
    minutes: number;
    show: boolean;
    isSimple?: boolean;
    isUsersPage?: boolean;
}

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    text-transform: uppercase;
  }
`;

const ChangeRolePopup: FC<ChangeRolePopupProps> = ({ minutes, show, isSimple }) => {
    const seconds = minutes * 60
    const [timer] = useLogout(seconds, show, isSimple);

    useEffect(() => {
        if (timer == 0) {
            Api.logOut();
        }
    }, [show, timer])

    return <SimplePopup show={show} header="Warning">
        <strong>Warning!</strong>
        <p>Your role was changed. You will be logged out in order to apply the changes.</p>
        <ButtonSection>
            <Button onClick={() => Api.logOut()}>Log Out</Button>
        </ButtonSection>
    </SimplePopup>
}

export default ChangeRolePopup;
