import React, { useState, useEffect, ChangeEvent, FC, Dispatch } from 'react';
import {
  Icon,
  StyledPopup,
  StyledPopupHeader,
  PopupContainerComponent,
  UploadDocPopup
} from '@ocr-gateway/ui';
import { useDispatch } from 'react-redux';
import withCreateHub from '../../../HOCs/withCreateHub';
import { FileUploadErrorT } from '@ocr-gateway/shared';
import { ContentContainer, PopupText } from '../../styles';
import { SmartHubList } from './SmartHubList';

interface withSmartHubAndClassifcationProps {
  show: boolean;
  setShowUploadPopup: Dispatch<React.SetStateAction<FileUploadErrorT>>;
  headerText: string;
  gcvMode: boolean;
}

const UploadWithSmartHubAndClassifcationComponent = ({
  show,
  setShowUploadPopup,
  uploadDocForHub,
  setUploadDocForTPopup,
  gcvMode,
  headerText = 'Upload sample documents',
  ...props
}: any) => {
  const [useSmartHub, setUseSmartHub] = useState(false);
  const [showDocumentUploadWarningPopup, setShowDocumentUploadWarningPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setUseSmartHub(false);
  }, [show]);

  const onSmartHubChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setUseSmartHub(checked);
  };

  const handleClosePopup = (e: React.MouseEvent) => {
    const hasDocuments = uploadDocForHub.documentsForUpload.length > 0;
    const isShowingSuccess = uploadDocForHub.showSuccess;

    if (hasDocuments) {
      setShowDocumentUploadWarningPopup(true);
      return;
    }

    (!hasDocuments || isShowingSuccess) &&
      dispatch(
        setUploadDocForTPopup({
          show: false,
          showLoader: false,
          hubId: void 0,
          documentsForUpload: [],
          initialDocumentsForUpload: []
        })
      );
  };

  return (
    <PopupContainerComponent show={show} onClose={handleClosePopup}>
      <StyledPopup>
        <StyledPopupHeader>
          &nbsp;
          {!uploadDocForHub.showLoader && headerText}
          <Icon pointer onClick={handleClosePopup} name="close" className="close" />
        </StyledPopupHeader>
        {!uploadDocForHub.showLoader && (
          <div style={{ marginBottom: '10px' }}>
            <PopupText>
              Once you create a hub, all the documents of the same format will be automatically
              assigned to this hub.
            </PopupText>
          </div>
        )}
        <ContentContainer>
          {useSmartHub ? (
            show && <SmartHubList onCancel={handleClosePopup} />
          ) : (
            <UploadDocPopup
              showWarningPopup={showDocumentUploadWarningPopup}
              handleShowWarningPopup={setShowDocumentUploadWarningPopup}
              onClose={handleClosePopup}
              uploadDocForHub={uploadDocForHub}
              setUploadDocForTPopup={setUploadDocForTPopup}
              gcvMode={gcvMode}
              {...props}
            />
          )}
        </ContentContainer>
      </StyledPopup>
    </PopupContainerComponent>
  );
};

export const UploadWithSmartHubAndClassifcation = withCreateHub(
  UploadWithSmartHubAndClassifcationComponent
);
