import React, { FC, useState, useEffect, useCallback, useRef } from 'react'

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
]

const useLogout = (startTime, showPopup, isSimple) => {

    let intervalRef = useRef(null);

    const [timer, setTimer] = useState(startTime);

    const resetTimer = useCallback(() => {
        setTimer(startTime);
    }, [startTime]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (timer > 0) {
                setTimer((prev) => prev - 1);
            }
        }, 1000)

        // if Warning Popup is shown, do not catch events
        !showPopup && events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        // reset timer when return to the OCR tab in browser
        // if the Popup is Simple, no need to reset the timer
        !isSimple && window.addEventListener('focus', resetTimer);

        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });
        };

    }, [resetTimer, showPopup])
    return [timer, resetTimer] as const;
}

export default useLogout;
