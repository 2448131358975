//@ts-nocheck
import React, { ChangeEvent, memo, useState } from 'react';
import {
  deepEqual,
  GetStartedPopup,
  Menu as MenuComponent,
  WorkflowCreationPopup,
  UserPrompt,
  toast
} from '@ocr-gateway/ui';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { batch, connect } from 'react-redux';
import {
  setGetStartedPopup,
  setWorkflowCreationPopup,
  setPdfWarningPopup,
  setUploadPopup,
  setPdfQualifierWarningPopup,
  setUploadDocForTPopupTensorflow,
  setUploadPopupWithSmarCapture,
  setSmartHubPopup,
  setCurrentHub,
  setIsSmartHub,
  useControlVisibility,
  setUserPrompt,
  Api,
  HUB_TYPE,
  IHub,
  setCreateOutlineRequest,
  menuWidth,
  menuWidth1481,
  navbarHeight
} from '@ocr-gateway/shared';
import axios from 'axios';
import AdminConsoleMenu from './AdminConsoleMenu';
import AccountSettingsMenu from './AccountSettingsMenu';
import { UploadDocumentPopup } from '../UploadDocumentPopup';
import { memoCompareApiHistory, withApiHistory } from '../HOCs/withApiHistory';

import { linkToHubs, navigation } from '../../constants';

import { useCheckIsSupportRole } from '../../hooks/useRole';

import UploadDocPopupContainer from '../UploadDocPopupContainer';

import { SmartHubPopupContainer } from '../PopupContainers';

import { UploadWithSmartHubAndClassifcation } from '../PopupContainers/Popups/SmartHub/UploadWithSmartHubAndClassifcation';

const StyledMenu = styled(MenuComponent)`
  z-index: 1111;
`;

export const MenuFuncComponent = withApiHistory(
  memo(
    ({
      isApiPage,
      isAdminConsolePage,
      isAccountSettingsPage,
      expanded,
      dispatch,
      popups,
      avatarUrl,
      isOwnerRole,
      isAdministratorRole,
      isHubsOutlineEdit,
      isBillingManagerRole,
      isHubsOutlineSimpleEdit,
      hubWithoutQualifier,
      uploadDocForHubTensorflow,
      uploadDocForSmartCapture,
      createOutlineRequest,
      smartCaptureEnabled,
      exportedCsv
    }: any) => {
      const [useSmartHub, setUseSmartHub] = useState(false);
      const history = useHistory();
      const location = useLocation();
      const {
        organization: { showBilling }
      } = useControlVisibility();

      const handleGetStartedClick = (e: React.MouseEvent) => {
        if (!isHubsOutlineEdit && !isHubsOutlineSimpleEdit) {
          dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
        } else if (isHubsOutlineSimpleEdit) {
          if (!exportedCsv) {
            dispatch(setPdfWarningPopup({ show: true, onGetStartedClick: true }));
          } else {
            dispatch(setPdfWarningPopup({ onGetStartedClick: true }));
            dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
          }
        } else if (hubWithoutQualifier) {
          dispatch(setPdfQualifierWarningPopup({ show: true, onGetStartedClick: true }));
        } else {
          dispatch(setPdfQualifierWarningPopup({ onGetStartedClick: true }));
          dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
        }
      };

      const handleGetStartedClose = (e: React.MouseEvent) => {
        dispatch(setGetStartedPopup({ show: false, showWhenCreateHub: false }));
        setUseSmartHub(false);
      };

      const handleWorkflowCreationClose = () => {
        dispatch(setWorkflowCreationPopup({ show: false }));
      };

      const handleShowUploadPopup = (state: any) => {
        dispatch(setUploadPopup(state));
      };

      const handleUploadDisabledClick = (e: React.MouseEvent) => {
        batch(() => {
          dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
          dispatch(setUploadPopup({ show: true, simple: true }));
        });
      };

      const handleUploadEnabledClick = (e: React.MouseEvent) => {
        batch(() => {
          dispatch(setGetStartedPopup({ show: true, showWhenCreateHub: false }));
          if (useSmartHub && smartCaptureEnabled) {
            dispatch(setSmartHubPopup({ show: true }));
          } else if (!useSmartHub && smartCaptureEnabled) {
            dispatch(
              setUploadDocForTPopupTensorflow({
                show: true,
                showSuccess: false,
                showLoader: false,
                hubId: ''
              })
            );
          } else {
            dispatch(setUploadPopup({ show: true, simple: false }));
          }
        });
      };

      const onSmartHubChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setUseSmartHub(checked);
      };

      const handleSmartHubPopupClose = () => {
        dispatch(setSmartHubPopup({ show: false }));
        setUseSmartHub(false);
      };

      const handleCreateWorkflow = () => {
        dispatch(setWorkflowCreationPopup({ show: true, onlyWorkflowPopup: false }));
      };

      const handleGetHubDefaultName = async () => {
        const { name } = await Api.getHubDefaultName();

        return name;
      };

      const handleCreateHub = async (hub: IHub) => {
        const isSmartHub = hub.type === HUB_TYPE.SMART;

        dispatch(setWorkflowCreationPopup({ showLoader: true }));

        try {
          const { id } = await Api.createNewHub({ dispatch, hub });

          history.push(`${linkToHubs}/${id}`, { hubId: id });

          batch(() => {
            dispatch(setGetStartedPopup({ show: false }));
            dispatch(setWorkflowCreationPopup({ show: false }));
            dispatch(setCurrentHub(null));
            dispatch(setIsSmartHub(isSmartHub));
          });
        } catch (msg) {
          toast.error(msg, { autoClose: 3000 });
        } finally {
          dispatch(setWorkflowCreationPopup({ showLoader: false }));
        }
      };

      const handleCloseUserPrompt = () => {
        dispatch(setUserPrompt({ show: false }));
      };

      const handleSaveUserPrompt = (userPrompt: any) => {
        Api.sendUserPrompt(userPrompt);

        handleCloseUserPrompt();
      };

      const isSupportRole = useCheckIsSupportRole();

      return (
        <>
          {isAdminConsolePage && <AdminConsoleMenu expanded={expanded} avatarUrl={avatarUrl} />}
          {isAccountSettingsPage && (
            <>
              <AccountSettingsMenu
                expanded={expanded}
                avatarUrl={avatarUrl}
                showUsers={isOwnerRole || isAdministratorRole || isSupportRole}
                showUsage={isOwnerRole || isSupportRole}
                showBilling={showBilling && (isOwnerRole || isBillingManagerRole)}
              />
            </>
          )}
          {!isApiPage && !isAdminConsolePage && !isAccountSettingsPage && (
            <>
              <StyledMenu
                expanded={expanded}
                width={menuWidth}
                width1481={menuWidth1481}
                linkComponent={NavLink}
                height={`calc(100vh - ${navbarHeight})`}
                navigation={navigation}
                onUploadClick={handleGetStartedClick}
                buttonText="get started"
                history={history}
                location={location}
              />
              <UploadDocumentPopup
                contentText="Drag and drop file"
                headerText="Upload a sample document"
                showUploadPopup={popups.upload}
                setShowUploadPopup={handleShowUploadPopup}
                onBackdropClick={handleGetStartedClose}
              />
              {/* Popup on press 'CREATE A HUB' button */}
              <UploadWithSmartHubAndClassifcation
                show={popups.uploadDocForSmartCapture.show}
                uploadDocForHub={uploadDocForSmartCapture}
                setUploadDocForTPopup={setUploadPopupWithSmarCapture}
                Api={Api}
                axios={axios}
                dispatch={dispatch}
                gcvMode={false}
                setCreateOutlineRequest={setCreateOutlineRequest}
                createOutlineRequest={createOutlineRequest}
                headerText="Upload sample documents"
              />
              {!useSmartHub && smartCaptureEnabled && uploadDocForHubTensorflow.show && (
                <UploadDocPopupContainer
                  setUploadDocForTPopup={setUploadDocForTPopupTensorflow}
                  setGetStartedPopup={setGetStartedPopup}
                  Api={Api}
                  dispatch={dispatch}
                  uploadDocForHub={uploadDocForHubTensorflow}
                  axios={axios}
                  headerText="Upload sample documents"
                  gcvMode={false}
                  setCreateOutlineRequest={setCreateOutlineRequest}
                  createOutlineRequest={createOutlineRequest}
                />
              )}
              <SmartHubPopupContainer
                show={popups.smartHubPopup.show}
                onClose={handleSmartHubPopupClose}
                onCancel={handleSmartHubPopupClose}
              />
              <GetStartedPopup
                show={popups.getStarted}
                onClose={handleGetStartedClose}
                onBtn1CLick={handleUploadDisabledClick}
                onBtn2CLick={handleUploadEnabledClick}
                onCreateWorkflow={handleCreateWorkflow}
                btn1Text="capture now"
                btn2Text="get started"
                onSmartHubChange={onSmartHubChange}
                smartCaptureEnabled={useSmartHub}
                withSmartCapture={smartCaptureEnabled}
              />
              <WorkflowCreationPopup
                {...popups.workflowCreation}
                onGetHubDefaultName={handleGetHubDefaultName}
                onCreateHub={handleCreateHub}
                onClose={handleWorkflowCreationClose}
                onBackdropClick={handleGetStartedClose}
                Api={Api}
              />
              <UserPrompt
                {...popups.userPrompt}
                onSave={handleSaveUserPrompt}
                onClose={handleCloseUserPrompt}
              />
            </>
          )}
        </>
      );
      //  : null
    },
    (prevProps, nextProps) => {
      const samePopup = deepEqual(prevProps.popups, nextProps.popups);
      const sameAvatarUrl = prevProps.avatarUrl === nextProps.avatarUrl;
      const sameEmail = prevProps.email === nextProps.email;
      const sameOwnerRole = prevProps.isOwnerRole === nextProps.isOwnerRole;
      const sameAdministratorRole = prevProps.isAdministratorRole === nextProps.isAdministratorRole;
      const sameBillingRoleRole = prevProps.isBillingManagerRole === nextProps.isBillingManagerRole;
      const sameHubWithoutQualifier =
        prevProps.hubWithoutQualifier === nextProps.hubWithoutQualifier;
      const sameUploadState =
        prevProps.uploadDocForHubTensorflow === nextProps.uploadDocForHubTensorflow;
      const sameCreateOutlineRequest =
        prevProps.createOutlineRequest === nextProps.createOutlineRequest;
      const sameSmartCapture = prevProps.smartCaptureEnabled === nextProps.smartCaptureEnabled;
      const sameExportedCsv = prevProps.exportedCsv === nextProps.exportedCsv;
      const showSmartHubErrorPopup =
        prevProps.showSmartHubErrorPopup === nextProps.showSmartHubErrorPopup;

      const sameProps =
        samePopup &&
        sameAvatarUrl &&
        sameEmail &&
        sameOwnerRole &&
        sameAdministratorRole &&
        sameHubWithoutQualifier &&
        sameUploadState &&
        sameCreateOutlineRequest &&
        sameSmartCapture &&
        sameExportedCsv &&
        showSmartHubErrorPopup &&
        sameBillingRoleRole;

      return memoCompareApiHistory(prevProps, nextProps) && sameProps;
    }
  )
);

function mapStateToProps(state: any) {
  return {
    popups: state.popups,
    avatarUrl: state.auth.user.avatarUrl,
    email: state.auth.user.email,
    isOwnerRole: state.auth.isOwnerRole,
    isBillingManagerRole: state.auth.isBillingManagerRole,
    isAdministratorRole: state.auth.isAdministratorRole,
    hubWithoutQualifier: state.hubs.hubWithoutQualifier,
    uploadDocForHubTensorflow: state.popups.uploadDocForHubTensorflow,
    uploadDocForSmartCapture: state.popups.uploadDocForSmartCapture,
    createOutlineRequest: state.currentDocument.createOutlineRequest,
    smartCaptureEnabled: state.auth.user.organization.smartCapture,
    exportedCsv: state.currentDocument.exportedCsv
  };
}

export const Menu = connect(mapStateToProps)(MenuFuncComponent);
