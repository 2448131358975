import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";

export const SmartHubPopupStyled = styled.div`
  width: 896px;
  background: #F3F3F5;
  padding: 55px 80px;
  color: #303036;
  text-align: center;
  position: relative;
  .close {
    position: absolute;
    top: 17px;
    right: 17px;
  }
  .content {
    margin: 51px auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    height: 300px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 10px;
  }
  .smart-hub-option {
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #F7FAFF;
    }
  }
`

export const SmartHubCheckboxSection = styled.div`
.smart-hub-checkbox {
  display: flex;
}
.smart-hub-tooltip {
    margin-left: 10px;
    display: flex;
  }
`

export const ContentContainer = styled.div`
  //height: 310px;
`

export const PopupText = styled.p`
  margin-bottom: 20px;
`


const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const StyledLoaderUpload = styled.div`
  height: 406px;
  min-width: 660px;
  text-align: center;
  color: #0B0B0B;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loader {
    animation: ${rotateAnimation} 1.5s ease-in-out infinite
  }
  .loading {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 14px;
    margin-top: 26px;
  }
  .text {
    font-size: 16px;
  }
`