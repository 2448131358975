import React, { memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  contentPaddingLeft,
  contentPaddingLeft1481,
  contentPaddingTop,
  menuWidth,
  menuWidth1481,
  menuWidthShrinked,
  navbarHeight
} from '@ocr-gateway/shared';
import { withApiHistory, memoCompareApiHistory } from './HOCs/withApiHistory';

interface StyledContentWrapperProps {
  isApiPage?: boolean;
  expanded?: boolean;
  isOutlinePage?: boolean;
  isAdminConsolePage?: boolean;
  isAccountSettingsPage?: boolean;
  isSendToFax?: boolean;
}

// ask nichita
const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  position: absolute;
  top: ${navbarHeight};
  left: ${menuWidth};
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - ${navbarHeight});
  transition: 125ms linear;
  ${({ isAdminConsolePage, isAccountSettingsPage }) =>
    (isAdminConsolePage || isAccountSettingsPage) &&
    css`
      border-top: 1px solid rgba(2, 9, 53, 0.3);
      background: #fff;
    `}
    ${({ isSendToFax }) =>
      isSendToFax &&
      css`
        overflow-x: hidden;
        overflow-y: hidden;
      `}
  ${({ isApiPage, expanded, isOutlinePage }) =>
    isApiPage
      ? css`
          border-top: 1px solid rgba(2, 9, 53, 0.3);
          left: 0;
          width: 100%;
        `
      : css`
          left: ${menuWidth};
          width: calc(100% - ${menuWidth});
          padding: ${contentPaddingTop} ${contentPaddingLeft};
          ${!expanded &&
            css`
              left: ${menuWidthShrinked};
              width: calc(100% - ${menuWidthShrinked});
            `}
          ${isOutlinePage &&
            css`
              padding: 0;
            `}
        `}
  @media only screen and(max-width: 1481px) {
    width: calc(100% - ${menuWidth1481});
    left: ${menuWidth1481};
    ${({ isOutlinePage }) =>
      isOutlinePage
        ? css`
            padding: 0;
          `
        : css`
            padding: ${contentPaddingTop} ${contentPaddingLeft1481};
          `}
    ${({ expanded }) =>
      !expanded &&
      css`
        left: ${menuWidthShrinked};
        width: calc(100% - ${menuWidthShrinked});
      `}
  }
`;

export const ContentWrapper = withApiHistory(
  memo(
    ({
      children,
      isApiPage,
      isOutlinePage,
      isAdminConsolePage,
      isAccountSettingsPage,
      isSendToFax,
      expanded
    }: any) => {
      return (
        <StyledContentWrapper
          expanded={expanded}
          isApiPage={isApiPage}
          isOutlinePage={isOutlinePage}
          isAdminConsolePage={isAdminConsolePage}
          isAccountSettingsPage={isAccountSettingsPage}
          isSendToFax={isSendToFax}
          id="scroll"
        >
          {children}
        </StyledContentWrapper>
      );
    },
    memoCompareApiHistory
  )
);
