import { useSelector } from 'react-redux';
import { getAuthHeader, IS_LOCALHOST, AUTH_LOCAL, AUTH_TYPE } from '@ocr-gateway/shared';

export const useAuthHeader = () => {
  const accessToken = useSelector((state: any) => state.auth.accessToken);

  let authHeaderValue = getAuthHeader(accessToken);

  if (IS_LOCALHOST) {
    if (AUTH_TYPE === 'key') {
      authHeaderValue = getAuthHeader(AUTH_LOCAL.key);
    }
  }

  return { Authorization: authHeaderValue };
};
