import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { Icon, PopupUpload } from '@ocr-gateway/ui';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import withCreateHub from './HOCs/withCreateHub';
import { setCreateOutlineRequest } from '@ocr-gateway/shared';

const popupAnimateCSS = css`
  z-index: 9999;
  transition: 100ms linear;

  /* Hidden init state */
  opacity: 0;
  &.entered {
    /* Animate in state */
    opacity: 1;
    .popup {
      transform: scale(1);
    }
  }
  &.exiting,
  &.exited {
    /* Animate out state */
    opacity: 0;
    .popup {
      transform: scale(1.15);
    }
  }

  .popup {
    transform: scale(0.85);
    transition: 100ms linear;
    will-change: transform;
  }
`;

const Portal = (props: any) => {
  const Popup = createPortal(props.children, document.body);
  return Popup;
};

const PopupContainer = styled.div<{ styles?: any; fixed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `};

  ${({ styles }) => {
    if (styles && styles.styles) {
      return styles.styles;
    }
    return '';
  }}

  span.error {
    color: #ffffff;
    background: #ff233a;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 6px 12px;
    position: absolute;
    transform: translate(226px, -26px);
  }

  input.error {
    border: 1px solid #ff233a;
    color: #ff233a;
  }
`;

const StyledPopup = styled.div`
  background: #fff;
  padding: 50px 45px;
  width: 750px;
`;

const StyledPopupHeader = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0b0b0b;
  font-size: 1.875rem;
  font-weight: bold;
  margin: 0 0 25px 0;
`;

interface UploadDocumentPopupProps {
  buttonText?: string;
  contentText?: string;
  headerText?: string;
  setShowUploadPopup: (obj: any) => any;
  showUploadPopup: any;
  dispatch?: any;
  outline?: any;
  reprocessRequest?: any;
  reprocessedOutline?: any;
  currentDocument?: any;
  handleChooseFileClick?: any;
  fileUploadError?: any;
  setFileUploadError?: any;
  makeRequest?: any;
  createOutlineRequest?: any;
  onBackdropClick?: any;
}

export const UploadDocumentPopupComponent = ({
  buttonText = 'Choose File',
  contentText = 'Drag and drop files',
  headerText = 'Upload Document',
  setShowUploadPopup,
  showUploadPopup,
  handleChooseFileClick,
  fileUploadError,
  setFileUploadError,
  createOutlineRequest,
  onBackdropClick
}: UploadDocumentPopupProps) => {
  const dispatch = useDispatch();

  const handleCloseUploadDocumentPopup = () => {
    setShowUploadPopup({ show: false });
    if (createOutlineRequest) {
      createOutlineRequest.cancel();
      dispatch(setCreateOutlineRequest(null));
    }
  };

  const handleContainerClick = (e: any) => {
    const {
      target: { classList }
    } = e;
    const isContainer = classList.contains('container');
    if (isContainer) {
      handleCloseUploadDocumentPopup();

      if (onBackdropClick) {
        onBackdropClick();
      }
    }
  };

  return (
    <Transition mountOnEnter unmountOnExit timeout={100} in={showUploadPopup.show}>
      {state => {
        const className = state;
        return (
          <Portal>
            <PopupContainer
              styles={css}
              className={`container ${className}`}
              fixed
              onClick={handleContainerClick}
            >
              <StyledPopup className="popup">
                <StyledPopupHeader>
                  &nbsp;
                  {showUploadPopup.showLoader ? '' : headerText}
                  <Icon onClick={handleCloseUploadDocumentPopup} pointer name="close" />
                </StyledPopupHeader>
                <PopupUpload
                  showLoader={showUploadPopup.showLoader}
                  buttonText={buttonText}
                  contentText={contentText}
                  headerText={headerText}
                  onClose={handleCloseUploadDocumentPopup}
                  className={className}
                  css={popupAnimateCSS}
                  onChooseFile={handleChooseFileClick}
                  showUploadItems={fileUploadError && fileUploadError.show}
                  fileUploadError={fileUploadError}
                  setFileUploadError={setFileUploadError}
                />
              </StyledPopup>
            </PopupContainer>
          </Portal>
        );
      }}
    </Transition>
  );
};

function mapStateToProps(state: any) {
  return {
    reprocessedOutline: state.currentDocument.reprocessedOutline,
    reprocessRequest: state.currentDocument.reprocessRequest,
    currentDocument: state.currentDocument,
    createOutlineRequest: state.currentDocument.createOutlineRequest
  };
}

export const UploadDocumentPopup = connect(mapStateToProps)(
  withCreateHub(UploadDocumentPopupComponent)
);
