import { useSelector } from 'react-redux';

export enum Roles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  SUPPORT = 'SUPPORT',
  OWNER = 'OWNER',
  USER = 'USER'
}

function useRole() {
  return useSelector((state: any) => state.auth.user.roles);
}

function useRoleCheck(roleToCheck: Roles) {
  const roles = useRole();
  return roles && roles.length && roles.some((role: string) => role === roleToCheck);
}

export function useCheckIsAdministratorRole() {
  return useRoleCheck(Roles.ADMINISTRATOR);
}

export function useCheckIsSupportRole() {
  return useRoleCheck(Roles.SUPPORT);
}

export function useCheckIsUserRole() {
  return useRoleCheck(Roles.USER);
}
export function useCheckIsOwnerRole() {
  return useRoleCheck(Roles.OWNER);
}
