import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AccountSettingsMenu } from '@ocr-gateway/ui';
import { menuWidth, menuWidth1481, navbarHeight } from '@ocr-gateway/shared';
import { UserType, UserApiProps } from './AccountSettingsMenu';
import { adminConsoleNavigation } from '../../constants';

interface AdminConsoleProps {
  avatarUrl: string;
  expanded?: boolean;
}

const AdminConsoleMenu = ({ avatarUrl, expanded }: AdminConsoleProps): React.ReactElement => {
  const { firstname, lastname } = useSelector<UserApiProps, UserType>(state => state.auth.user);

  return (
    <AccountSettingsMenu
      expanded={expanded}
      avatarUrl={avatarUrl}
      width={menuWidth}
      height={`calc(100vh - ${navbarHeight})`}
      width1481={menuWidth1481}
      linkComponent={NavLink}
      navigation={adminConsoleNavigation}
      firstname={firstname}
      lastname={lastname}
      style={{
        borderTop: '1px solid rgba(2, 9, 53, 0.3)'
      }}
    />
  );
};

export default AdminConsoleMenu;
