import React, { useState, useEffect } from 'react'
import { SmartHubPopupContainerI } from "../../types";
import { Icon, PopupContainerComponent, StyledPopupHeader } from "@ocr-gateway/ui";
import { SmartHubPopupStyled } from "../../styles";
import { SmartHubList } from './SmartHubList';

export const SmartHubPopupContainer = ({ show, onClose, onCancel }: SmartHubPopupContainerI) => {

  const handleContainerClick = (e: any) => {
    onClose && onClose(e)
  };

  return (
    <PopupContainerComponent show={show} onClose={handleContainerClick}>
      <SmartHubPopupStyled>
        <>
          <Icon pointer onClick={onCancel} name='close' className='close' />
          <StyledPopupHeader>
            Choose the template which best describes the document type you want to upload.
              </StyledPopupHeader>
          {show && <SmartHubList onCancel={onCancel} />}
        </>
      </SmartHubPopupStyled>
    </PopupContainerComponent>
  )
}