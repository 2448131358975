import React from 'react';
import { MenuNavigationType, AccountSettingsMenuNavigationType } from '@ocr-gateway/ui';
import { RouteType, apiKeysHash } from '@ocr-gateway/shared';

const Hubs = React.lazy(() => import('./pages/Hubs'));
const Trash = React.lazy(() => import('./pages/Trash'));
const Help = React.lazy(() => import('./pages/Help'));
const HubEdit = React.lazy(() => import('./pages/HubEdit'));
const Hub = React.lazy(() => import('./pages/Hubs/HubPage'));
const Documents = React.lazy(() => import('./pages/Documents'));
const FaxHistory = React.lazy(() => import('./pages/FaxHistory'));
const DownloaderJobs = React.lazy(() => import('./pages/DownloaderJobs'));
const SendFax = React.lazy(() => import('./pages/SendFax'));
const Home = React.lazy(() => import('./pages/Home'));
const Reports = React.lazy(() => import('./pages/Reports'));
const Api = React.lazy(() => import('./pages/Api'));
const Integrations = React.lazy(() => import('./pages/Integrations'));
const WebhookCreate = React.lazy(() => import('./pages/Webhook/WebHookCreate'));
const WebhookGrid = React.lazy(() => import(`./pages/Webhook/WebhooksGrid`));
const WebhookEdit = React.lazy(() => import(`./pages/Webhook/WebhoockEdit`));
const WebhookFailedTransactions = React.lazy(() =>
  import(`./pages/Webhook/WebhookFailedTransactionsGrid`)
);
const DirectUploadIntegrations = React.lazy(() => import('./pages/DirectUploadIntegrations'));
const EmailIntegrations = React.lazy(() => import('./pages/EmailIntegrations'));
const FaxIntegrations = React.lazy(() => import('./pages/FaxIntegrations'));
const GoogleChannelCreate = React.lazy(() =>
  import('./pages/GoogleDriveIntegrations/GoogleChannelCreate')
);
const GoogleChannelEdit = React.lazy(() =>
  import('./pages/GoogleDriveIntegrations/GoogleChannelEdit')
);
const GoogleSheetEdit = React.lazy(() => import('./pages/GoogleSheetIntegrations/GoogleSheetEdit'));
const GoogleChannelGrid = React.lazy(() =>
  import('./pages/GoogleDriveIntegrations/GoogleChannelGrid')
);
const GoogleSpreadSheetCreate = React.lazy(() =>
  import('./pages/GoogleSheetIntegrations/GoogleSheetCreate')
);
const GoogleSheetGrid = React.lazy(() => import('./pages/GoogleSheetIntegrations/GoogleSheetGrid'));
const AddGoogleAccount = React.lazy(() =>
  import('./pages/GoogleDriveIntegrations/AddGoogleAccount')
);
const OneDriveChannelCreate = React.lazy(() =>
  import('./pages/OneDriveIntegrations/OneDriveChannelCreate')
);
const OneDriveChannelEdit = React.lazy(() =>
  import('./pages/OneDriveIntegrations/OneDriveChannelEdit')
);
const OneDriveChannelGrid = React.lazy(() =>
  import('./pages/OneDriveIntegrations/OneDriveChannelGrid')
);
const AddOneDriveAccount = React.lazy(() =>
  import('./pages/OneDriveIntegrations/AddOneDriveAccount')
);
const QuickBooksGrid = React.lazy(() => import('./pages/QuickBooksIntegrations/QuickBooksGrid'));
const QuickBooksCreate = React.lazy(() =>
  import('./pages/QuickBooksIntegrations/QuickBooksCreate')
);
const QuickBooksEdit = React.lazy(() => import('./pages/QuickBooksIntegrations/QuickBooksEdit'));
const AddQuickBooksAccount = React.lazy(() =>
  import('./pages/QuickBooksIntegrations/AddQuickBooksAccount')
);

const HubSpotGrid = React.lazy(() => import('./pages/HubSpotIntegrations/HubSpotGrid'));
const HubSpotCreate = React.lazy(() => import('./pages/HubSpotIntegrations/HubSpotCreate'));
const HubSpotEdit = React.lazy(() => import('./pages/HubSpotIntegrations/HubSpotEdit'));
const AddHubSpotAccount = React.lazy(() => import('./pages/HubSpotIntegrations/AddHubSpotAccount'));

const SalesforceGrid = React.lazy(() => import('./pages/SalesforceIntegrations/SalesforceGrid'));
const SalesforceCreate = React.lazy(() =>
  import('./pages/SalesforceIntegrations/SalesforceCreate')
);
const SalesforceEdit = React.lazy(() => import('./pages/SalesforceIntegrations/SalesforceEdit'));
const AddSalesforceAccount = React.lazy(() =>
  import('./pages/SalesforceIntegrations/AddSalesforceAccount')
);

const PosterBotCreate = React.lazy(() => import('./pages/PosterBotIntegrations/PosterBotCreate'));
const PosterBotGrid = React.lazy(() => import('./pages/PosterBotIntegrations/PosterBotGrid'));
const PosterBotEdit = React.lazy(() => import('./pages/PosterBotIntegrations/PosterBotEdit'));
const DownloaderBotCreate = React.lazy(() =>
  import('./pages/DownloaderBotIntegrations/DownloaderBotCreate')
);
const DownloaderBotGrid = React.lazy(() =>
  import('./pages/DownloaderBotIntegrations/DownloaderBotGrid')
);
const DownloaderBotEdit = React.lazy(() =>
  import('./pages/DownloaderBotIntegrations/DownloaderBotEdit')
);
const FaxBotCreate = React.lazy(() => import('./pages/FaxBotIntegrations/FaxBotCreate'));
const FaxBotGrid = React.lazy(() => import('./pages/FaxBotIntegrations/FaxBotGrid'));

const FaxBotEdit = React.lazy(() => import('./pages/FaxBotIntegrations/FaxBotEdit'));
//
const RenamerBotCreate = React.lazy(() =>
  import('./pages/RenamerBotIntegrations/RenamerBotCreate')
);
const RenamerBotGrid = React.lazy(() => import('./pages/RenamerBotIntegrations/RenamerBotGrid'));
const RenamerBotEdit = React.lazy(() => import('./pages/RenamerBotIntegrations/RenamerBotEdit'));
//
const EsignatureBotCreate = React.lazy(() =>
  import('./pages/EsignatureBotIntegrations/EsignatureBotCreate')
);
const EsignatureBotGrid = React.lazy(() =>
  import('./pages/EsignatureBotIntegrations/EsignatureBotGrid')
);
const EsignatureBotEdit = React.lazy(() =>
  import('./pages/EsignatureBotIntegrations/EsignatureBotEdit')
);
//
const EmailerBotCreate = React.lazy(() =>
  import('./pages/EmailerBotIntegrations/EmailerBotCreate')
);
const EmailerBotGrid = React.lazy(() => import('./pages/EmailerBotIntegrations/EmailerBotGrid'));
const EmailerBotEdit = React.lazy(() => import('./pages/EmailerBotIntegrations/EmailerBotEdit'));
//
const ComposerBotCreate = React.lazy(() =>
  import('./pages/ComposerBotIntegrations/ComposerBotCreate')
);
const ComposerBotGrid = React.lazy(() => import('./pages/ComposerBotIntegrations/ComposerBotGrid'));
const ComposerBotEdit = React.lazy(() => import('./pages/ComposerBotIntegrations/ComposerBotEdit'));
//
const AdminConsole = React.lazy(() => import('./pages/AdminConsole'));
const AdminConsoleCompanies = React.lazy(() => import('./pages/AdminConsoleCompanies'));
const AdminConsoleUser = React.lazy(() => import('./pages/AdminConsoleUser'));
const AccountSettings = React.lazy(() => import('./pages/AccountSettings'));
const AccountSettingsUsers = React.lazy(() => import('./pages/AccountSettingsUsers'));
const AccountSettingsBilling = React.lazy(() => import('./pages/AccountSettingsBilling'));
const AccountSettingsUsage = React.lazy(() => import('./pages/AccountSettingsUsage'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const PasswordCreation = React.lazy(() => import('./pages/PasswordCreation'));
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));

export const linkToHome = '/';
export const linkToApi = '/keys-api';
export const linkToApiWithHash: string = linkToApi + apiKeysHash;
export const linkToDocuments = '/documents';
export const linkToFaxHistory = `/fax-history`;
export const linkToDownloaderJobs = `/site-reader-jobs/report`;
export const linkToSendFax = `${linkToDocuments}/send-fax`;
export const linkToHubs = '/hubs';
export const linkToHub = `${linkToHubs}/:id`;
export const linkToHubEditPostfix = `/outline/:outlineId/edit`;
export const linkToHubEdit = `${linkToHub}${linkToHubEditPostfix}`;
export const linkToIntegrations = '/integrations';
export const linkToReports = '/reports';
export const linkToWebhookPrefix = `${linkToIntegrations}/webhooks/`;
export const linkToWebHooksCreate = `${linkToWebhookPrefix}create`;
export const linkToWebhookFailedTransactionsPrefix: string = linkToWebhookPrefix;
export const linkToWebhookFailedTransactionsPostfix = `failed-transactions`;
export const linkToWebhookFailedTransactions = `${linkToWebhookFailedTransactionsPrefix}:webhookId/${linkToWebhookFailedTransactionsPostfix}`;
export const linkToWebHooksGrid: string = linkToWebhookPrefix;
export const linkToWebhookEditPostfix = '/edit';
export const linkToEditWebhook = `${linkToWebhookPrefix}:id${linkToWebhookEditPostfix}`;
export const linkToDirectUploadIntegrations = `${linkToIntegrations}/direct-upload`;
export const linkToEmailIntegrations = `${linkToIntegrations}/email`;
export const linkToFaxIntegrations = `${linkToIntegrations}/fax`;

export const linkToGoogleSheetGrid = `${linkToIntegrations}/google-sheets`;
export const linkToGoogleSheetCreate = `${linkToGoogleSheetGrid}/create`;
export const linkToGoogleSheetEditPostfix = '/edit';
export const linkToGoogleSheetEdit = `${linkToGoogleSheetGrid}/:id${linkToGoogleSheetEditPostfix}`;

export const linkToFaxBotGrid = `${linkToIntegrations}/fax-bot`;
export const linkToFaxBotCreate = `${linkToFaxBotGrid}/create`;
export const linkToFaxBotEditPostfix = '/edit';
export const linkToFaxBotEdit = `${linkToFaxBotGrid}/:id${linkToFaxBotEditPostfix}`;

export const linkToDownloaderBotGrid = `${linkToIntegrations}/site-reader`;
export const linkToDownloaderBotCreate = `${linkToDownloaderBotGrid}/create`;
export const linkToDownloaderBotEditPostfix = '/edit';
export const linkToDownloaderBotEdit = `${linkToDownloaderBotGrid}/:id${linkToDownloaderBotEditPostfix}`;

export const linkToPosterBotGrid = `${linkToIntegrations}/poster`;
export const linkToPosterBotCreate = `${linkToPosterBotGrid}/create`;
export const linkToPosterBotEditPostfix = '/edit';
export const linkToPosterBotEdit = `${linkToPosterBotGrid}/:id${linkToPosterBotEditPostfix}`;

export const linkToRenamerBotGrid = `${linkToIntegrations}/renamer`;
export const linkToRenamerBotCreate = `${linkToRenamerBotGrid}/create`;
export const linkToRenamerBotEditPostfix = '/edit';
export const linkToRenamerBotEdit = `${linkToRenamerBotGrid}/:id${linkToRenamerBotEditPostfix}`;

export const linkToEsignatureBotGrid = `${linkToIntegrations}/esignature`;
export const linkToEsignatureBotCreate = `${linkToEsignatureBotGrid}/create`;
export const linkToEsignatureBotEditPostfix = '/edit';
export const linkToEsignatureBotEdit = `${linkToEsignatureBotGrid}/:id${linkToEsignatureBotEditPostfix}`;

export const linkToComposerBotGrid = `${linkToIntegrations}/composer-bot`;
export const linkToComposerBotCreate = `${linkToComposerBotGrid}/create`;
export const linkToComposerBotEditPostfix = '/edit';
export const linkToComposerBotEdit = `${linkToComposerBotGrid}/:id${linkToComposerBotEditPostfix}`;

export const linkToEmailerBotGrid = `${linkToIntegrations}/emailer`;
export const linkToEmailerBotCreate = `${linkToEmailerBotGrid}/create`;
export const linkToEmailerBotEditPostfix = '/edit';
export const linkToEmailerBotEdit = `${linkToEmailerBotGrid}/:id${linkToEmailerBotEditPostfix}`;

export const linkToQuickBooksGrid = `${linkToIntegrations}/quickbooks`;
export const linkToQuickBooksCreate = `${linkToQuickBooksGrid}/create`;
export const linkToQuickBooksEdit = `${linkToQuickBooksGrid}/:id/edit`;
export const linkToAddQuickBooksAccount = `${linkToQuickBooksGrid}/add-account`;

export const linkToHubSpotGrid = `${linkToIntegrations}/hubspot`;
export const linkToHubSpotCreate = `${linkToHubSpotGrid}/create`;
export const linkToHubSpotEdit = `${linkToHubSpotGrid}/:id/edit`;
export const linkToAddHubSpotAccount = `${linkToHubSpotGrid}/add-account`;

export const linkToSalesforceGrid = `${linkToIntegrations}/salesforce`;
export const linkToSalesforceCreate = `${linkToSalesforceGrid}/create`;
export const linkToSalesforceEdit = `${linkToSalesforceGrid}/:id/edit`;
export const linkToAddSalesforceAccount = `${linkToSalesforceGrid}/add-account`;

export const linkToGoogleDriveIntegrations = `${linkToIntegrations}/google-drive/`;
export const linkToAddGoogleAccount = `${linkToGoogleDriveIntegrations}add-account`;
export const linkToGoogleDriveChannelCreate = `${linkToGoogleDriveIntegrations}create`;
export const linkToGoogleDriveChannelEditPostfix = '/edit';
export const linkToGoogleDriveChannelEdit = `${linkToGoogleDriveIntegrations}:id${linkToGoogleDriveChannelEditPostfix}`;
export const linkToGoogleDriveChannelGrid = `${linkToGoogleDriveIntegrations}channels`;

export const linkToOneDriveIntegrations = `${linkToIntegrations}/one-drive/`;
export const linkToAddOneDriveAccount = `${linkToOneDriveIntegrations}add-account`;
export const linkToOneDriveChannelCreate = `${linkToOneDriveIntegrations}create`;
export const linkToOneDriveChannelEditPostfix = '/edit';
export const linkToOneDriveChannelEdit = `${linkToOneDriveIntegrations}:id${linkToOneDriveChannelEditPostfix}`;
export const linkToOneDriveChannelGrid = `${linkToOneDriveIntegrations}channels`;

export const linkToTrash = '/trash';
export const linkToHelp = '/help';
export const linkToAdminConsole = '/admin-console';
export const linkToAdminConsoleCompanies = `${linkToAdminConsole}/companies`;
export const linkToAdminConsoleUsers = `${linkToAdminConsole}/user`;
export const linkToAdminConsoleUser = `${linkToAdminConsoleUsers}/:id`;
export const linkToAccountSettings = '/account-settings';
export const linkToAccountSettingsUsers = `${linkToAccountSettings}/users`;
export const linkToAccountSettingsBilling = `${linkToAccountSettings}/billing`;
export const linkToAccountSettingsUsage = `${linkToAccountSettings}/usage`;
export const linkToAccountUsage = `${linkToAccountSettings}/usage`;
export const linkToUserRegistration = '/register-invite';
export const linkToPasswordCreation = '/create-password';

export const adminConsoleNavigation: AccountSettingsMenuNavigationType = [
  {
    text: 'Internal Users',
    icon: 'people',
    linkTo: linkToAdminConsole
  },
  {
    text: 'Companies',
    icon: 'bag',
    linkTo: linkToAdminConsoleCompanies
  }
];

export const accountSettingsNavigation: AccountSettingsMenuNavigationType = [
  {
    text: 'Account Settings',
    icon: 'settings2',
    linkTo: linkToAccountSettings
  }
];

export const accountSettingsUsersNavigation = {
  text: 'Users',
  icon: 'people',
  linkTo: linkToAccountSettingsUsers
};

export const accountSettingsUsageNavigation = {
  text: 'Usage',
  icon: 'settings3',
  linkTo: linkToAccountSettingsUsage
};

export const accountSettingsBillingNavigation = {
  text: 'Billing',
  icon: 'billing',
  linkTo: linkToAccountSettingsBilling
};

export const navigation: MenuNavigationType = [
  {
    linkTo: linkToHome,
    active: true,
    text: 'Home',
    icon: 'home',
    id: 'home'
  },
  {
    linkTo: linkToDocuments,
    icon: 'file',
    text: 'Documents',
    id: 'documents'
  },
  {
    linkTo: linkToHubs,
    icon: 'menu-hubs',
    text: 'Hubs',
    id: 'hubs'
  },
  {
    linkTo: linkToIntegrations,
    icon: 'integrations3',
    text: 'Integrations',
    id: 'integrations'
  },
  {
    linkTo: linkToReports,
    icon: 'reports',
    text: 'Reports',
    id: 'reports'
  }
];

export const predefinedHostnameRoutes = [
  {
    path: linkToUserRegistration,
    component: SignUp
  },
  {
    path: linkToPasswordCreation,
    component: PasswordCreation
  },
  {
    path: '*',
    component: SignIn
  }
];

export const routes: Array<RouteType> = [
  {
    path: linkToHome,
    component: Home
  },
  {
    path: linkToApi,
    component: Api
  },
  {
    path: linkToDocuments,
    component: Documents
  },
  {
    path: linkToFaxHistory,
    component: FaxHistory
  },
  {
    path: linkToDownloaderJobs,
    component: DownloaderJobs
  },
  {
    path: linkToSendFax,
    component: SendFax
  },
  {
    path: linkToHubs,
    component: Hubs
  },
  {
    path: linkToIntegrations,
    component: Integrations
  },
  {
    path: linkToReports,
    component: Reports
  },
  {
    path: linkToTrash,
    component: Trash
  },
  {
    path: linkToHub,
    component: Hub
  },
  {
    path: linkToHubEdit,
    component: HubEdit
  },
  {
    path: linkToWebHooksCreate,
    component: WebhookCreate
  },
  {
    path: linkToEditWebhook,
    component: WebhookEdit
  },
  {
    path: linkToWebHooksGrid,
    component: WebhookGrid
  },
  {
    path: linkToWebhookFailedTransactions,
    component: WebhookFailedTransactions
  },
  {
    path: linkToDirectUploadIntegrations,
    component: DirectUploadIntegrations
  },
  {
    path: linkToEmailIntegrations,
    component: EmailIntegrations
  },
  {
    path: linkToFaxIntegrations,
    component: FaxIntegrations
  },
  {
    path: linkToGoogleDriveChannelCreate,
    component: GoogleChannelCreate
  },
  {
    path: linkToGoogleDriveChannelEdit,
    component: GoogleChannelEdit
  },
  {
    path: linkToGoogleSheetEdit,
    component: GoogleSheetEdit
  },
  {
    path: linkToGoogleDriveChannelGrid,
    component: GoogleChannelGrid
  },
  {
    path: linkToAddGoogleAccount,
    component: AddGoogleAccount
  },
  {
    path: linkToOneDriveChannelCreate,
    component: OneDriveChannelCreate
  },
  {
    path: linkToOneDriveChannelEdit,
    component: OneDriveChannelEdit
  },
  {
    path: linkToOneDriveChannelGrid,
    component: OneDriveChannelGrid
  },
  {
    path: linkToAddOneDriveAccount,
    component: AddOneDriveAccount
  },
  {
    path: linkToGoogleSheetGrid,
    component: GoogleSheetGrid
  },
  {
    path: linkToGoogleSheetCreate,
    component: GoogleSpreadSheetCreate
  },
  {
    path: linkToPosterBotCreate,
    component: PosterBotCreate
  },
  {
    path: linkToPosterBotGrid,
    component: PosterBotGrid
  },
  {
    path: linkToPosterBotEdit,
    component: PosterBotEdit
  },
  {
    path: linkToDownloaderBotGrid,
    component: DownloaderBotGrid
  },
  {
    path: linkToDownloaderBotCreate,
    component: DownloaderBotCreate
  },
  {
    path: linkToDownloaderBotEdit,
    component: DownloaderBotEdit
  },
  {
    path: linkToFaxBotCreate,
    component: FaxBotCreate
  },
  {
    path: linkToFaxBotGrid,
    component: FaxBotGrid
  },
  {
    path: linkToFaxBotEdit,
    component: FaxBotEdit
  },
  {
    path: linkToRenamerBotCreate,
    component: RenamerBotCreate
  },
  {
    path: linkToRenamerBotGrid,
    component: RenamerBotGrid
  },
  {
    path: linkToRenamerBotEdit,
    component: RenamerBotEdit
  },
  {
    path: linkToEsignatureBotCreate,
    component: EsignatureBotCreate
  },
  {
    path: linkToEsignatureBotGrid,
    component: EsignatureBotGrid
  },
  {
    path: linkToEsignatureBotEdit,
    component: EsignatureBotEdit
  },
  {
    path: linkToComposerBotCreate,
    component: ComposerBotCreate
  },
  {
    path: linkToComposerBotGrid,
    component: ComposerBotGrid
  },
  {
    path: linkToComposerBotEdit,
    component: ComposerBotEdit
  },
  {
    path: linkToEmailerBotCreate,
    component: EmailerBotCreate
  },
  {
    path: linkToEmailerBotGrid,
    component: EmailerBotGrid
  },
  {
    path: linkToEmailerBotEdit,
    component: EmailerBotEdit
  },
  {
    path: linkToQuickBooksGrid,
    component: QuickBooksGrid
  },
  {
    path: linkToQuickBooksCreate,
    component: QuickBooksCreate
  },
  {
    path: linkToQuickBooksEdit,
    component: QuickBooksEdit
  },
  {
    path: linkToAddQuickBooksAccount,
    component: AddQuickBooksAccount
  },
  {
    path: linkToHubSpotGrid,
    component: HubSpotGrid
  },
  {
    path: linkToHubSpotCreate,
    component: HubSpotCreate
  },
  {
    path: linkToHubSpotEdit,
    component: HubSpotEdit
  },
  {
    path: linkToAddHubSpotAccount,
    component: AddHubSpotAccount
  },
  {
    path: linkToSalesforceGrid,
    component: SalesforceGrid
  },
  {
    path: linkToSalesforceCreate,
    component: SalesforceCreate
  },
  {
    path: linkToSalesforceEdit,
    component: SalesforceEdit
  },
  {
    path: linkToAddSalesforceAccount,
    component: AddSalesforceAccount
  },
  {
    path: linkToAdminConsole,
    component: AdminConsole,
    isPrivate: true,
    allowedRoles: ['SUPER_USER']
  },
  {
    path: linkToAdminConsoleCompanies,
    component: AdminConsoleCompanies,
    isPrivate: true,
    allowedRoles: ['SUPER_USER']
  },
  {
    path: linkToAdminConsoleUser,
    component: AdminConsoleUser,
    isPrivate: true,
    allowedRoles: ['SUPER_USER']
  },
  {
    path: linkToAccountSettings,
    component: AccountSettings
  },
  {
    path: linkToAccountSettingsUsers,
    component: AccountSettingsUsers,
    isPrivate: true,
    allowedRoles: ['OWNER', 'ADMINISTRATOR', 'SUPPORT', 'BILLING_MANAGER']
  },
  {
    path: linkToAccountSettingsBilling,
    component: AccountSettingsBilling,
    isPrivate: true,
    allowedRoles: ['OWNER', 'BILLING_MANAGER']
  },
  {
    path: linkToAccountSettingsUsage,
    component: AccountSettingsUsage,
    isPrivate: true,
    allowedRoles: ['OWNER', 'SUPPORT', 'BILLING_MANAGER']
  },
  {
    path: '*',
    component: PageNotFound
  }
];
