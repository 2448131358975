import React, { FC, useEffect, useState } from 'react';
import { AccountSettingsMenu as AccountSettingsMenuComponent } from '@ocr-gateway/ui';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  accountSettingsNavigation,
  accountSettingsUsersNavigation,
  accountSettingsUsageNavigation,
  accountSettingsBillingNavigation
} from '../../constants';
import { menuWidth, menuWidth1481, navbarHeight } from '@ocr-gateway/shared';

interface AccountSettingsProps {
  avatarUrl: string;
  showUsers: boolean;
  showUsage: boolean;
  showBilling: boolean;
  expanded?: boolean;
}

export type UserType = {
  firstname: string;
  lastname: string;
};
export interface UserApiProps {
  auth: { user: UserType };
}

const AccountSettingsMenu: FC<AccountSettingsProps> = ({
  avatarUrl,
  showUsers,
  showUsage,
  showBilling,
  expanded
}: AccountSettingsProps) => {
  const { firstname, lastname } = useSelector<UserApiProps, UserType>(state => state.auth.user);
  const [navigation, setNavigation] = useState([...accountSettingsNavigation]);

  useEffect(() => {
    const navigationWithUsers = [...(accountSettingsNavigation as any)];
    if (showUsers) {
      navigationWithUsers.push(accountSettingsUsersNavigation);
    }

    if (showUsage) {
      navigationWithUsers.push(accountSettingsUsageNavigation);
    }
    if (showBilling) {
      navigationWithUsers.push(accountSettingsBillingNavigation);
    }
    setNavigation(navigationWithUsers);
  }, [showUsers, showUsage]);

  return (
    <AccountSettingsMenuComponent
      expanded={expanded}
      avatarUrl={avatarUrl}
      width={menuWidth}
      height={`calc(100vh - ${navbarHeight})`}
      width1481={menuWidth1481}
      linkComponent={NavLink}
      navigation={navigation}
      firstname={firstname}
      lastname={lastname}
      style={{
        borderTop: '1px solid rgba(2, 9, 53, 0.3)'
      }}
    />
  );
};

export default AccountSettingsMenu;
