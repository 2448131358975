import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadErrorT } from './types';
import {
  clearOutline,
  resetReprocessedIsSet,
  setReprocessRequest,
  setSimpleExtraction
} from '@ocr-gateway/shared';
import { Api } from '@ocr-gateway/shared';
import { UploadFileMetaData } from '@ocr-gateway/ui';
import { linkToHubEditPostfix, linkToHubs } from '../../constants';

const hocSelector = (state: any) => ({
  reprocessedOutline: state.currentDocument.reprocessedOutline,
  reprocessRequest: state.currentDocument.reprocessRequest,
  currentDocument: state.currentDocument
});

const withCreateHub = (Component: any) => {
  return (props: any) => {
    const { setShowUploadPopup, showUploadPopup, onBackdropClick } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const { reprocessedOutline, reprocessRequest, currentDocument } = useSelector(hocSelector);

    const [fileUploadError, setFileUploadError] = useState<FileUploadErrorT>({
      show: false,
      file: {
        name: '',
        size: 0
      }
    });

    const clearOutlineData = (outlineKey: string, keysToClear: string[]) => {
      const outline = currentDocument[outlineKey];
      if (outline.id) {
        Api.deleteOutline({
          dispatch,
          outlineId: outline.id,
          clearState: true,
          stateKey: keysToClear
        });
      }
      if (reprocessedOutline.id) {
        Api.deleteOutline({
          dispatch,
          outlineId: reprocessedOutline.id,
          clearState: true,
          stateKey: 'reprocessedOutline'
        });
      }
      dispatch(resetReprocessedIsSet());
    };

    const preparePayload = (base64file: string, file: UploadFileMetaData) => ({
      dispatch,
      base64file,
      mimeType: file.type,
      fileName: file.name,
      data: file,
      ...(showUploadPopup && showUploadPopup.simple
        ? {
            isSimpleExtraction: true,
            needPreprocess: false
          }
        : {})
    });

    const resetPreviousData = () => {
      if (reprocessRequest) {
        reprocessRequest.cancel();
        dispatch(setReprocessRequest(null));
      }
      clearOutlineData('simpleOutline', ['outline', 'simpleOutline']);
    };
    const createHub = async (payload: any) => {
      try {
        let hubId;
        let outlineId;
        if (showUploadPopup && showUploadPopup.simple) {
          //Both promises are kept because the response payload is different for both which crashes the application
          //const outlineResponse = await Api.createOutline(payload);
          const outlineResponse = await Api.createOutlineTryItNow(payload); //This is for the websocket connection reprocessing
          sessionStorage.setItem('outlineResponse', JSON.stringify(outlineResponse));
          dispatch(setSimpleExtraction(true));

          hubId = 'simple';
          outlineId = outlineResponse.id;
        } else {
          hubId = await Api.createHub(payload);

          let data = await Api.getHub({
            dispatch,
            id: hubId.id,
            config: { include: ['outline', 'channels'] }
          });
          outlineId = data.outline.id;
        }

        history.push({
          pathname: `${linkToHubs}/${hubId}/outline/${outlineId}/edit`,
          state: {
            isCreateHubMode: true
          }
        });
        return hubId;
      } catch (e) {
        console.log({ e });
      }
    };

    const makeRequest = async (base64file: string, file: UploadFileMetaData) => {
      const payload = preparePayload(base64file, file);
      resetPreviousData();
      const hubId = await createHub(payload);
      return hubId;
    };

    const handleChooseFileClick = async (file: UploadFileMetaData) => {
      const base64file = file && file.result!.split(',').pop();
      if (base64file.length <= Math.round(10 * 1024 * 1024 * 1.25)) {
        setFileUploadError(prevState => {
          if (prevState && prevState.show) {
            return {
              show: false,
              file: {
                name: file.name,
                size: file.size
              }
            };
          }
        });

        setShowUploadPopup &&
          setShowUploadPopup({
            ...showUploadPopup,
            showLoader: true
          });
        await makeRequest(base64file, file);
        if (onBackdropClick) {
          onBackdropClick();
        }

        setShowUploadPopup &&
          setShowUploadPopup({
            ...showUploadPopup,
            show: false
          });
      } else {
        setFileUploadError({
          show: true,
          file: {
            name: file.name,
            size: file.size
          }
        });
      }
    };

    return (
      <Component
        {...props}
        handleChooseFileClick={handleChooseFileClick}
        fileUploadError={fileUploadError}
        setFileUploadError={setFileUploadError}
        makeRequest={makeRequest}
      />
    );
  };
};

export default withCreateHub;
