import React, { useState } from 'react'
import { UploadDocPopup } from "@ocr-gateway/ui";
import withCreateHub from "./HOCs/withCreateHub";
import {
  Icon,
  StyledPopup,
  StyledPopupHeader,
  PopupContainerComponent
} from '@ocr-gateway/ui';
import { useDispatch } from 'react-redux'

const UploadDocPopupContainer = ({
  uploadDocForHub,
  headerText = 'Upload documents using this hub',
  setUploadDocForTPopup,
  gcvMode,
  countOfFiles = 10,
  ...props
}: any) => {
  const dispatch = useDispatch();
  const [showDocumentUploadWarningPopup, setShowDocumentUploadWarningPopup] = useState(false);

  const { show, showLoader, showSuccess } = uploadDocForHub;

  const onClose = (e: React.MouseEvent) => {
    const hasDocuments = uploadDocForHub.documentsForUpload.length > 0;
    const isShowingSuccess = uploadDocForHub.showSuccess;

    if (hasDocuments) {
      setShowDocumentUploadWarningPopup(true);
      return;
    }

    (!hasDocuments || isShowingSuccess) &&
      dispatch(
        setUploadDocForTPopup({
          show: false,
          hubId: void 0,
          documentsForUpload: [],
          initialDocumentsForUpload: []
        })
      );
  };

  return (
    <PopupContainerComponent show={show} onClose={onClose}>
      <StyledPopup>
        <StyledPopupHeader>
          <span>
            {!showSuccess && gcvMode || !gcvMode && !showLoader ? headerText : ''}
          </span>
          <Icon pointer onClick={onClose} name="close" className="close" />
        </StyledPopupHeader>
        <UploadDocPopup
          showWarningPopup={showDocumentUploadWarningPopup}
          handleShowWarningPopup={setShowDocumentUploadWarningPopup}
          onClose={onClose}
          uploadDocForHub={uploadDocForHub}
          setUploadDocForTPopup={setUploadDocForTPopup}
          gcvMode={gcvMode}
          countOfFiles={countOfFiles}
          {...props}
        />
      </StyledPopup>
    </PopupContainerComponent>
  )

}

export default withCreateHub(UploadDocPopupContainer)