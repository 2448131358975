import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  linkToApi,
  linkToAccountSettings,
  linkToAdminConsole,
  linkToHubEditPostfix,
  linkToHubs,
  linkToSendFax
} from '../../constants';

export const withApiHistory = (Component: any) => {
  return (props: any) => {
    const [state, setState] = useState<any>({
      isApiPage: void 0,
      isOutlinePage: void 0,
      isSendToFax: void 0,
      isAdminConsolePage: void 0,
      isAccountSettingsPage: void 0,
      isHubsOutlineEdit: void 0,
      isHubsOutlineSimpleEdit: void 0
    });
    const {
      isApiPage,
      isOutlinePage,
      isAdminConsolePage,
      isAccountSettingsPage,
      isHubsOutlineEdit,
      isSendToFax,
      isHubsOutlineSimpleEdit
    } = state;
    const history = useHistory();
    const {
      location: { pathname }
    } = history;
    const setDesiredState = (pathname: string) => {
      const isApiPage = pathname.includes(linkToApi);
      const isAdminConsolePage = pathname.includes(linkToAdminConsole);
      const isAccountSettingsPage = pathname.includes(linkToAccountSettings);
      const isOutlinePage =
        pathname.startsWith(linkToHubs) &&
        pathname.endsWith('/edit') &&
        pathname.includes('/outline');
      const isSendToFax = pathname.includes(linkToSendFax);
      const isHubsOutlineEdit =
        pathname.startsWith(linkToHubs) &&
        pathname.endsWith('/edit') &&
        !pathname.includes('/simple');
      const isHubsOutlineSimpleEdit = pathname.includes('/hubs/simple/outline/edit');

      setState({
        isApiPage,
        isOutlinePage,
        isAdminConsolePage,
        isAccountSettingsPage,
        isHubsOutlineEdit,
        isHubsOutlineSimpleEdit,
        isSendToFax
      });
    };

    useEffect(() => {
      setDesiredState(pathname);
      const unlisten = history.listen(location => {
        const { pathname } = location;
        setDesiredState(pathname);
      });
      return () => {
        unlisten();
      };
    }, [pathname]);
    React.useEffect(() => {
      const isOnCaptureNow = window.location.pathname.includes('hubs/simple/outline/');
      const reprocessedIsSetSessionStorage = sessionStorage.getItem('reprocessedIsSet');
      const outlineResponseSessionStorage = sessionStorage.getItem('outlineResponse');
      const captureNowStateSessionStorage = sessionStorage.getItem('captureNowState');
      if (reprocessedIsSetSessionStorage && !isOnCaptureNow) {
        sessionStorage.removeItem('reprocessedIsSet');
      }
      if (outlineResponseSessionStorage && !isOnCaptureNow) {
        sessionStorage.removeItem('outlineResponse');
      }
      if (captureNowStateSessionStorage && !isOnCaptureNow) {
        sessionStorage.removeItem('captureNowState');
      }
    }, [window.location.pathname]);

    return (
      <Component
        isOutlinePage={isOutlinePage}
        isApiPage={isApiPage}
        isAdminConsolePage={isAdminConsolePage}
        isAccountSettingsPage={isAccountSettingsPage}
        isHubsOutlineEdit={isHubsOutlineEdit}
        isHubsOutlineSimpleEdit={isHubsOutlineSimpleEdit}
        isSendToFax={isSendToFax}
        {...props}
      />
    );
  };
};

export const memoCompareApiHistory = (prev: any, next: any) => {
  const prevIsAdminConsolePage = prev.isAdminConsolePage;
  const nextIsAdminConsolePage = next.isAdminConsolePage;
  const sameIsAdminConsolePage = prevIsAdminConsolePage === nextIsAdminConsolePage;

  const prevIsAccountSettingsPage = prev.isAccountSettingsPage;
  const nextIsAccountSettingsPage = next.isAccountSettingsPage;
  const sameIsAccountSettingsPage = prevIsAccountSettingsPage === nextIsAccountSettingsPage;

  const prevIsApiPage = prev.isApiPage;
  const nextIsApiPage = next.isApiPage;
  const sameIsApiPage = prevIsApiPage === nextIsApiPage;

  const prevExpanded = prev.expanded;
  const nextExpanded = next.expanded;
  const sameExpanded = prevExpanded === nextExpanded;

  const prevIsOutlinePage = prev.isOutlinePage;
  const nextIsOutlinePage = next.isOutlinePage;
  const sameIsOutlinePage = prevIsOutlinePage === nextIsOutlinePage;

  const prevIsHubsSimpleOutlineEdit = prev.isHubsSimpleOutlineEdit;
  const nextIsHubsSimpleOutlineEdit = next.isHubsSimpleOutlineEdit;
  const sameIsHubsSimpleOutlineEdit = prevIsHubsSimpleOutlineEdit === nextIsHubsSimpleOutlineEdit;

  return (
    sameIsApiPage &&
    sameExpanded &&
    sameIsOutlinePage &&
    sameIsAdminConsolePage &&
    sameIsAccountSettingsPage &&
    sameIsHubsSimpleOutlineEdit
  );
};

export default withApiHistory;
